import axios from '@/libs/axios'

const endpoint = '/synchronization'
const getCategory = () => axios.get(`${endpoint}/category`)
const getProduct = () => axios.get(`${endpoint}/product`)
const getCustomer = () => axios.get(`${endpoint}/customer`)
export default {
  getCategory,
  getProduct,
  getCustomer,
}

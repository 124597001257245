<template>
  <div>
    <div
      class="text-center mb-3"
    >
      <img
        width="300px"
        height="60px"
        :src="cscart"
        alt="logo"
      >
      <p class="font-large-1">
        cs.cart
      </p>
    </div>
    <h4 class="font-weight-bolder text-body mb-2">
      {{ $t('Available Actions') }}
    </h4>
    <b-row>
      <b-col md="3">
        <p
          class="font-weight-bolder font-medium-5"
        >
          {{ $t('Categories') }}
        </p>
        <p>In CS Cart: {{ getCategories.cscartCategoriesSum }}</p>
        <p>In Llama: {{ getCategories.llamaCategoriesSum }}</p>
        <b-button
          class="cancelBtn font-medium-2 font-weight-bolder"
          @click="fetchCategories"
        >
          <feather-icon
            icon="RefreshCwIcon"
            size="18"
            class="mr-1"
          />
          {{ $t('Sync') }}
        </b-button>
      </b-col>
      <b-col md="3">
        <p
          class="font-weight-bolder font-medium-5"
        >
          {{ $t('Products') }}
        </p>
        <p>In CS Cart: {{ getProducts.cscartProductsSum }}</p>
        <p>In Llama: {{ getProducts.llamaProductsSum }}</p>
        <b-button
          class="cancelBtn font-medium-2 font-weight-bolder"
          @click="fetchProducts"
        >
          <feather-icon
            icon="RefreshCwIcon"
            size="18"
            class="mr-1"
          />
          {{ $t('Sync') }}
        </b-button>
      </b-col>
      <b-col md="3">
        <p
          class="font-weight-bolder font-medium-5"
        >
          {{ $t('Customers') }}
        </p>
        <p>In CS Cart: {{ getCustomers.cscartCustomersSum }}</p>
        <p>In Llama: {{ getCustomers.llamaCustomersSum }}</p>
        <b-button
          class="cancelBtn font-medium-2 font-weight-bolder"
          @click="fetchCustomers"
        >
          <feather-icon
            icon="RefreshCwIcon"
            size="18"
            class="mr-1"
          />
          {{ $t('Sync') }}
        </b-button>
      </b-col>
      <b-col md="3">
        <p
          class="font-weight-bolder font-medium-5"
        >
          {{ $t('Log') }}
        </p>
        <p>Last Sync on:</p>
        <p>{{ getLastUpdate[0] }}</p>
      </b-col>
    </b-row>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="$router.push({ name: 'settings-general-integrations' })"
          >
            {{ $t('Back to List') }}
          </b-button>
        </div>
        <div />
      </div>
    </portal>
  </div>
</template>

<script>
import {
  BButton, BCol, BRow,
} from 'bootstrap-vue'
import store from '@/store'
import synchronization from '@/store/settings/general/integrations/synchronization'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-unresolved
import cscart from '../../../../../../assets/images/integrations/cs_cart.svg'

export default {
  name: 'CSCart',
  components: {
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      error: false,
      loading: false,
      cscart,
      getCustomers: {},
      getProducts: {},
      getCategories: {},
      getLastUpdate: {},
    }
  },
  mounted() {
    const actions = [
      'getCategories',
      'getProducts',
      'getCustomers',
      'getLastUpdate',
    ]
    const fetchData = action => this.$store.dispatch(`${this.MODULE_NAME_INTEGRATIONS}/${action}`)
      .then(response => {
        const { data } = response.data
        this[action] = data
      })
      .catch(err => {
        this.error = true
        this.errorNotification(this, err)
      })

    Promise.all(actions.map(action => fetchData(action)))
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    toastNotification() {
      this.loading = true
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Attention',
          icon: 'CoffeeIcon',
          variant: 'success',
          text: 'Synchronization process is already in progress!',
        },
      })
    },
    fetchCategories() {
      this.toastNotification()
      this.$store.dispatch(`${this.MODULE_NAME}/getCategory`).then(() => {
        this.loading = false
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      })
    },
    fetchProducts() {
      this.toastNotification()
      this.$store.dispatch(`${this.MODULE_NAME}/getProduct`).then(() => {
        this.loading = false
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      })
    },
    fetchCustomers() {
      this.toastNotification()
      this.$store.dispatch(`${this.MODULE_NAME}/getCustomer`).then(() => {
        this.loading = false
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      })
    },
  },
  setup() {
    const MODULE_NAME = 'synchronization'
    const MODULE_NAME_INTEGRATIONS = 'settingsIntegrations'

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, synchronization)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    const ecommerceList = {
      cscart: {
        image: 'cs_cart.svg',
        title: 'CS.Cart',
        class: 'border-0',
      },
    }

    return {
      ecommerceList,
      MODULE_NAME,
      MODULE_NAME_INTEGRATIONS,
    }
  },
}
</script>

<style scoped>

</style>
